<template>
    <div class="wrap">
        <div class="space_pc">
            <div class="payment_modal modal">
                <div class="payment_modal_box modal_box">
                    <div class="payment_modal_title modal_title">
                        <h1>결제내역</h1>
                        <div class="modal_close_btn" @click="$emit('closePayment')">
                            <img src="/media/img/close_btn.png" alt="">
                        </div>
                    </div>
                    <div class="payment_scroll_box scroll_body">
                        <div class="payment_scroll_box_header">
                            <p class="table-title first-title w_40">스페이스명</p>
                            <p class="table-title w_20">결제 여부</p>
                            <p class="table-title w_20">금액</p>
                            <p class="table-title w_20">결제 일시</p>
                        </div>
                        <div class="payment_scroll_box_content" v-for="(item, i) in payedlist" :key="i">
                            <div class="table-content first-content w_40">
                                <p v-html="item.spaceNm" />
                            </div>
                            <div class="table-content success w_20" v-if="item.payStatus === 'Y'">
                                <p>성공</p>
                            </div>
                            <div class="table-content fail w_20" v-else-if="item.payStatus === 'N'">
                                <p>실패</p>
                            </div>
                            <div class="table-content cancel w_20" v-else>
                                <p>취소</p>
                            </div>
                            <div class="table-content w_20">
                                <p>{{ item.payAmount | comma }}원</p>
                            </div>
                            <div class="table-content w_20">
                                <p>{{ dateTime(item.payDate) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="space_mo">
            <div class="payment_modal modal">
                <div class="payment_modal_box modal_box">
                    <div class="payment_modal_title modal_title">
                        <div class="mo_space_header">
                            <div class="mo_header_box">
                                <a href="#" @click="$emit('closePayment')">
                                    <img src="/media/images/icon_back_24.svg">
                                </a>
                                <h2>결제내역</h2>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="payment_scroll_box scroll_body">
                        <ul v-for="(item, index) in payedlist" :key="index">
                            <li>
                                <ul class="pay_info store">
                                    <li v-html="item.spaceNm" />
                                    <li v-if="item.payStatus === 'Y'"><span class="success">성공</span></li>
                                    <li v-else-if="item.payStatus === 'N'"><span class="fail">실패</span></li>
                                    <li v-else><span class="cancel">취소</span></li>
                                </ul>
                            </li>
                            <li>
                                <ul class="pay_info">
                                    <li>결제 일시</li>
                                    <li>{{ dateTime(item.payDate) }}</li>
                                </ul>
                            </li>
                            <li>
                                <ul class="pay_info">
                                    <li>금액</li>
                                    <li>{{ item.payAmount | comma }}원</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <header class="header mo_header">
                <div class="header_box">
                    <div class="logo">
                        <a>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"  @click="clickPre()">
                                <path d="M2.828 7.00072L7.778 11.9507L6.364 13.3647L0 7.00072L6.364 0.636719L7.778 2.05072L2.828 7.00072Z" fill="white"/>
                            </svg>
                            <h2>이용권 현황</h2>
                        </a>
                    </div>
                    <div class="right_menu">
                        <div class="payment_title">
                            <p>결제내역</p>
                        </div>
                        <div class="alarm_box">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.00024 18.666H20.3786" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <ellipse cx="17.6756" cy="6.44444" rx="4.32431" ry="4.44444" fill="#FF1150" v-if="$store.state.noticeCount !== 0"/>
                            </svg>
                        </div>
                        <div class="toggle">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2231_20993)">
                                    <path d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2231_20993">
                                        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VueCookies from "vue-cookies";
import dayjs from "dayjs";
import axios from "axios";

export default defineComponent({
  name: 'payedList',
  // props: {
  // spaceId: String
  // },
  props: [
    'spaceId'
  ],
  data () {
    return {
      payedlist: []
    };
  },
  methods: {
    clickPre () {
      history.back();
    },
    getPayedList () {
      var spaceId = this.spaceId;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/product/getPayList", { spaceId }, { headers })
        .then(res => {
          // console.log(res.data);
          this.payedlist = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    dateTime (value) {
      return dayjs(value).format("YYYY.MM.DD HH:mm");
      // return dayjs(value).format("YYYY.MM.DD");
    }
  },
  mounted () {
    this.getPayedList();
  },
  filters: {
    comma (val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
});
</script>

<style scoped>
/* common */
.w_40 { width: 40%; }
.w_20 { width: 20%; }

  .modal {
    display: block;
  }
  .table-title {
    padding: 14px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.64);
    border-top: 2px solid rgba(255, 255, 255, 0.64);
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  }
  /* .first-title {
    text-align: left;
    padding-left: 20px;
  } */
  .table-content {
    text-align: center;
    padding: 20px 0;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  .table-content.success p {
    padding: 2px 12px;
    font-size: 14px;
    color: #9CFF6E;
    background: rgba(156, 255, 110, 0.16);
    border-radius: 50px;
    margin-bottom: 0;
    width: 35%;
    margin: 0 auto;
  }
  .first-title{
    text-align:left;
  }

  .first-content {
    display: block;
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
  .table-content.fail p {
    padding: 2px 12px;
    font-size: 14px;
    color: #FA586A;
    background: rgba(250, 88, 106, 0.16);
    border-radius: 50px;
    margin-bottom: 0;
    width: 35%;
    margin: 0 auto;
  }

  .table-content.cancel p {
    padding: 2px 12px;
    font-size: 14px;
    color: #DDDDDD;
    background: rgba(221, 221, 221, 0.16);
    border-radius: 50px;
    margin-bottom: 0;
    width: 35%;
    margin: 0 auto;
  }
  .pay_info.store .success {
    padding: 2px 12px;
    font-size: 13px;
    color: #9CFF6E;
    background: rgba(156, 255, 110, 0.16);
    border-radius: 50px;
    margin-bottom: 0;
    margin: 0 auto;
  }
  .pay_info.store .fail {
    padding: 2px 12px;
    font-size: 14px;
    color: #FA586A;
    background: rgba(250, 88, 106, 0.16);
    border-radius: 50px;
    margin-bottom: 0;
    margin: 0 auto;
  }
  .pay_info.store .cancel {
    padding: 2px 12px;
    font-size: 14px;
    color: #DDDDDD;
    background: rgba(221, 221, 221, 0.16);
    border-radius: 50px;
    margin-bottom: 0;
    margin: 0 auto;
  }

  .payment_scroll_box_header {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .payment_scroll_box_content {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .payment_scroll_box_content .table-content.success p { width: 52px; }
  .payment_scroll_box_content .table-content.fail p { width: 52px; }
  .payment_scroll_box_content .table-content.cancel p { width: 52px; }

  @media all and (max-width: 850px) {
    .payment_scroll_box table tr td:nth-child(2) p {
      width: 50%
    }
    .table-content.fail p {
      width: 50%
    }
  }
  @media all and (max-width: 613px) {
    .payment_scroll_box table tr td:nth-child(2) p {
      width: 65%
    }
    .table-content.fail p {
      width: 65%
    }
  }
</style>
